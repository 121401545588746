.timeInput {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  height: 60px;
}

.timeInput > .react-time-picker__wrapper > .react-time-picker__inputGroup {
  padding: 16.5px 14px;
}

.timeInput.hasError > .react-time-picker__wrapper {
  border-color: red;
}
