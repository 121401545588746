@mixin desktop {
  @media (min-width: 601px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 600px) {
    @content;
  }
}
