@import "./util.module";

.homeContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: min(940px, 100%);
  //width: 100%;
  z-index: 2;
  border-radius: 10px;
  padding: 0.875rem;
  @include desktop {
    margin-top: 3rem;
  }
  @include mobile{
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
